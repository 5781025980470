<template>
    <!-- 请求统计 -->
    <div class="requestLog">
        <div class="equipmentTableC">
            <div class="companyTable">
                <el-table
                    v-loading="loading"
                    :data="visitData.elements"
                    style="width: 100%"
                    height="100%"
                >
                    <el-table-column
                        prop="logTime"
                        label="时间"
                        min-width="160"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="placeId"
                        label="场所ID"
                        align="center"
                        min-width="160"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="doorId"
                        label="点位ID"
                        align="center"
                        min-width="120"
                    >
                    </el-table-column>
                    <el-table-column label="接口" align="center" width="180">
                        <template slot-scope="scope">
                            <el-popover trigger="hover" placement="top">
                                <p>{{ scope.row.url }}</p>
                                <div slot="reference" class="name-wrapper">
                                    <el-tag size="medium">{{
                                        scope.row.url
                                    }}</el-tag>
                                </div>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="参数" align="center" width="180">
                        <template slot-scope="scope">
                            <el-popover trigger="hover" placement="top">
                                <p>{{ scope.row.params }}</p>
                                <div slot="reference" class="name-wrapper">
                                    <el-tag size="medium">{{
                                        scope.row.params
                                    }}</el-tag>
                                </div>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rsStatusA"
                        label="返回状态"
                        align="center"
                        min-width="100"
                    >
                    </el-table-column>
                    <el-table-column
                        label="返回结果"
                        align="center"
                        width="180"
                    >
                        <template class="wrapperSty" slot-scope="scope">
                            <el-popover trigger="hover" placement="top">
                                <p>{{ scope.row.rsData }}</p>
                                <div slot="reference" class="name-wrapper">
                                    <el-tag size="medium">{{
                                        scope.row.rsData
                                    }}</el-tag>
                                </div>
                            </el-popover>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination
                :current-page="currentPage"
                :page-size="visitData.pageSize"
                @current-change="handleCurrentChange"
                background
                layout="total, prev, pager, next"
                :total="visitData.totalRecords"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    name: 'requestLog',
    components: {},
    data() {
        return {
            loading: false,
            // 拉取到的 表格数据
            visitData: {},
            // 当前页数
            currentPage: 1,
            //列表-开始位置
            start: 1,
        }
    },
    created() {
        this.getVisit()
    },
    methods: {
        // 请求日志 列表
        getVisit() {
            this.loading = true

            this.$instance
                .get('/v1/log/list-page', {
                    params: {
                        pageNum: this.currentPage - this.start,
                        pageSize: 10,
                    },
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.loading = false
                        this.visitData = res.data.data
                        this.visitData.elements.map((val) => {
                            val.rsStatusA = val.rsStatus + '/' + val.rsMsg
                        })
                    }
                })
                .catch((error) => {
                    console.log('error: ', error)
                })
        },
        // 点击分页-当前页
        handleCurrentChange(val) {
            this.currentPage = val
            // 搜索并拉取列表
            this.getVisit()
        },
    },
}
</script>
<style lang="scss" scoped>
.requestLog {
    width: calc(100% - 40px);
    margin: 20px;
    padding: 20px 30px;
    background-color: #fff;
    box-sizing: border-box;
    .equipmentTableC {
        width: 100%;
        height: calc(100% - 44px);
        // 表格
        & ::v-deep .companyTable {
            height: 100%;
            border: 1px #f0f0f0 solid;
            overflow: auto;
            .el-tag--medium {
                width: 170px;
            }
            // .name-wrapper {
            //     margin-right: 20px;
            // }
        }
    }
}
</style>
